import { Button, Form, Input, Radio, Steps, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons'; 
import StepWizard from 'react-step-wizard';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode'; 
import './ConfirmReturn.less';

const { Step } = Steps;

function ConfirmReturn(){
    const [form, setForm] = useState([]);
    const [ativeStep, setActiveStep] = useState(1);
    const [businessKey, setBusinessKey] = useState('');
    const [isDelivered, setIsDelivered] = useState('');
    const [businessKeyValid, setBusinesskeyValid] = useState(false);
    const [isloading, setLoading] = useState(true);
    
    const [companyId, setCompanyId] = useState(1);

    const branchId = '';

    useEffect(() => {
        if(setBusinesskeyValid != true){ //maybe not needed, but safety base case
            let url = new URL(window.location);
            let paramBusinessKey = url.searchParams.get('bk');

            if(paramBusinessKey != '' && paramBusinessKey != null){
                fetch(`/api/verifylocker/${paramBusinessKey}`, {
                    method: 'GET',
                })
                .then((response) => {
                    
                    if(response.status == 204){
                        setBusinesskeyValid(true);
                        setBusinessKey(paramBusinessKey);
                    }
                    else{
                        setBusinesskeyValid(false);
                    }
                    setLoading(false);
                })
                .catch(error => console.log(error));
            }
            else{
                setLoading(false);
                setBusinesskeyValid(false);
            }
        }
    }, [form])

    //tokens?
    const confirmDelivery = (isDeliveredVal, props) => {
        
        if(isDeliveredVal == true){
            setIsDelivered('delivered')
        }
        else{
            setIsDelivered("notDelivered");
        }
        let confirmation = {
            customerPutCollectionInLocker: isDeliveredVal,
        }        
        
        fetch(`/api/verifylocker/${businessKey}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(confirmation),
        })
        .then((response) => {
            if(response.ok) {
                props.nextStep();
            }
            else{
                setIsDelivered('error');
                props.nextStep();
            }
        })
        
    };
     


    const Step1 = (props) => {
        if(isloading){
            return(
                <h2>Augnablik...</h2>
            )
        }
        else{
            if(!businessKeyValid){
                return(
                    <h2>Ógildur lykill</h2>
                )
            }
            else{
                return(
                    <Form>
                        <h2>Ertu búin/n að skila vörunni í afhendingarbox?</h2>
                        <Form.Item
                            name='confirmDelivery'
                            className='confirmReturnFormItem'
                        >
                            <Button onClick={() => confirmDelivery(true, props)}>
                                Já
                            </Button>
                        </Form.Item>
                        <Form.Item
                            className='confirmNonReturnFormItem'>
                            <Button onClick={() => confirmDelivery(false, props)}>
                                Nei
                            </Button>
                        </Form.Item>
                    </Form>
                )
            }
        }
    };
    const Step2 = (props) => {
        //user pressed ok
        var responseMsg = '';
        if(isDelivered == 'error'){
            return(
                <h2>Villa kom í staðfestingu.</h2>
            )
        }
        else if(isDelivered == 'delivered'){

            return (
                <h2>Takk fyrir að svara könnun !</h2>
            )
        }
        else{

            return (
                <h2>Takk fyrir að láta okkur vita !</h2>
            )
        }
      
    };
    

    const getActiveStep = () => {
        switch(activeStep){
            case 1:
                return 1;
            case 2:
                return 2;
     
        }
    }

    return(
        <div className="content">  
            <StepWizard
                onStepChange={(state) => setActiveStep(state.activeStep)}
                isHashEnabled={true}
                className='step-wizard'
                isLazyMount={true}
            >
                <Step1 hashKey="konnun" />
                <Step2 hashKey="skilad" />
            </StepWizard>
        </div>
    );
}

export default ConfirmReturn;